<template>
    <div class="component-container">
        <div class="totalRowsStyle">
            <label> Svarsrader: {{ getRowCount }}</label>
        </div>
        <div class="chart-container">
            <div class="chart-header">
                <CheckboxDropdownOptions
                    class="measure-dropdown"
                    dropdownInfoText="Målgrupper och mått"
                    @onInputChanged="setVisibleMeasures"
                    :initialSelection="[Object.values(getMeasuresKeyLabelPairs)[0]]"
                    labelTypeName="Målgrupper och mått"
                    :optionsArray="Object.values(getMeasuresKeyLabelPairs)"
                />
                <CheckboxDropdownOptions
                    class="provider-dropdown"
                    dropdownInfoText="Kanalhus"
                    @onInputChanged="setVisibleProviders"
                    :initialSelection="getProviders"
                    labelTypeName="Kanalhus"
                    :optionsArray="getProviders"
                />
            </div>
            <div class="chart">
                <LineChartGenerator :chart-options="chartOptions" :chart-data="visibleChartData" :chart-id="chartId" :dataset-id-key="datasetIdKey" class="line-chart" />
            </div>
        </div>
    </div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy"
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement } from "chart.js"
import CheckboxDropdownOptions from "../../Shared/FormElement/CheckboxDropdown/CheckboxDropdownOptions"
ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement)

export default {
    name: "ResultDiagramMainChannel",
    components: {
        LineChartGenerator,
        CheckboxDropdownOptions,
    },
    props: {
        chartId: {
            type: String,
            default: "line-chart",
        },
        datasetIdKey: {
            type: String,
            default: "label",
        },
    },
    data() {
        return {
            visibleRows: 50,
            availableChartData: { labels: [], datasets: [] },
            visibleChartData: { labels: [], datasets: [] },
            visibleProviders: [],
            visibleMeasures: [],
            maxMeasures: 6,
            predefinedChannelColors: {
                Discovery: ["#4472c4", "#83A2D8", "#44B2C4"],
                SVT: ["#ed8036", "#F4AC7C", "#CE5D12"],
                TV4: ["#989898", "#cbcbcb", "#545454"],
                "Viaplay Group": ["#ffc515", "#FFD65C", "#45FD02"],
            },
            chartOptions: {
                locale: "sv",
                maintainAspectRatio: false,
                hover: {
                    mode: "dataset",
                },
                scales: {
                    y: {
                        beginAtZero: false,
                        grid: {
                            display: true,
                            //show horizontal gridline only for every other tick
                            color: function(context) {
                                return context.index % 2 == 0 ? "#E5E5E5" : "transparent"
                            },
                        },
                        ticks: {
                            //needed to remove thousand divider " "
                            callback: function(value) {
                                return value
                            },
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: true,
                        position: "right",
                        labels: {
                            boxHeight: 10,
                            boxWidth: 40,
                            backgroundColor: "white",
                        },
                        onHover: this.handleHover,
                        onLeave: this.handleLeave,
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || ""

                                if (label) {
                                    label += ": "
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat("sv", { useGrouping: false }).format(context.parsed.y)
                                }
                                return label
                            },
                        },
                        animation: {
                            numbers: { duration: 0 },
                            colors: {
                                type: "color",
                                duration: 100,
                                from: "transparent",
                            },
                        },
                    },
                    // krävs för att undvika memory stack full error
                    annotation: {
                        annotations: [],
                    },
                },
                datasets: {
                    line: {
                        //common settings for all lines
                        borderWidth: 3,
                        hoverBorderWidth: 5,
                        pointHitRadius: 10,
                    },
                },
            },
        }
    },
    mounted() {
        this.setAvailableChartData(this.getDates, this.getProviders, this.getMeasuresKeyLabelPairs)
    },
    computed: {
        getRowCount() {
            let totalLength = this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites.length
            if (this.visibleRows > totalLength) {
                return `${totalLength}/${totalLength}`
            }
            return `${this.visibleRows}/${totalLength}`
        },
        // getRowCount() {
        //     return this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites.length
        // },
        levelOneSitesWithLevelTwoSites() {
            return this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites
        },
        fields() {
            let fields = [...this.$store.getters.getChannelsResults.data.columns]
            fields.unshift({ key: "plussign", tdClass: "plusminussigntd", thClass: "plusminussignth", label: "" }, { key: "chosen", thClass: "choose-checkbox-th", label: "Valda" })
            return fields
        },
        getMeasuresKeyLabelPairs() {
            let fields = [...this.$store.getters.getChannelsResults.data.columns]
            let newFields = Object.values(fields).reduce((all, m) => {
                if (m.key.includes("measure")) {
                    all[m.key] = `Sum of ${m.label}`
                    return all
                }
                return all
            }, [])
            return newFields
        },
        getDates() {
            let items = this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites
            return [...new Set(items.map(i => i.date))]
        },
        getProviders() {
            let items = this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites
            return [...new Set(items.filter(p => p.isParent).map(i => i.providerName))]
        },
    },
    watch: {
        levelOneSitesWithLevelTwoSites: function() {
            this.setAvailableChartData(this.getDates, this.getProviders, this.getMeasuresKeyLabelPairs)
        },
    },
    methods: {
        setAvailableChartData(dates, providers, measures) {
            //isParent har measure sum för kanal vid datum
            let items = this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites.filter(p => p.isParent)
            let chartDataSets = []
            for (let indexP = 0; indexP < providers.length; indexP++) {
                let providerItems = items.filter(p => p.providerName == providers[indexP])
                let measureData = []
                let measureKeys = Object.keys(measures)
                let color
                for (let indexM = 0; indexM < measureKeys.length; indexM++) {
                    let providerDate = []
                    for (let indexD = 0; indexD < dates.length; indexD++) {
                        providerDate.push(providerItems.filter(d => d.date == dates[indexD]))
                    }
                    measureData = providerDate.flat().map(d => {
                        return parseFloat(d[measureKeys[indexM]].replace(/,/g, ".")).toFixed(1)
                    })
                    //half of max measures get a new color
                    if (indexM >= this.maxMeasures / 2) {
                        color = this.predefinedChannelColors[providers[indexP]][1]
                    } else {
                        color = this.predefinedChannelColors[providers[indexP]][0]
                    }
                    chartDataSets.push({
                        label: `${providers[indexP]} ${measures[measureKeys[indexM]]}`,
                        data: measureData,
                        borderColor: color,
                        backgroundColor: "white",
                        borderDash: this.getBorderDash(indexM),
                        metadata: { provider: providers[indexP], measure: measures[measureKeys[indexM]] },
                    })
                }
            }
            this.availableChartData = {
                labels: this.getDates,
                datasets: chartDataSets,
            }
        },
        setVisibleChartData() {
            this.visibleChartData.labels = this.availableChartData.labels
            this.visibleChartData.datasets = this.availableChartData.datasets.filter(d => {
                return this.visibleProviders.includes(d.metadata.provider) && this.visibleMeasures.includes(d.metadata.measure)
            })
        },
        setVisibleProviders(providers) {
            this.visibleProviders = providers
            this.setVisibleChartData()
        },
        setVisibleMeasures(measures) {
            this.visibleMeasures = measures
            this.setVisibleChartData()
        },
        getBorderDash(measureIndex) {
            switch (measureIndex) {
                case 0:
                    return []
                case 1:
                    return [15, 5]
                case 2:
                    return [3, 2]
                case 3:
                    return [10, 3, 2, 3, 2, 3]
                case 4:
                    return [15, 3, 5, 3]
                case 5:
                    return [25, 5, 10, 5]
                default:
                    return []
            }
        },
        handleHover(evt, item, legend) {
            legend.chart.data.datasets.forEach((ds, index) => {
                ds.borderColor = index === item.datasetIndex || ds.borderColor.length === 9 ? ds.borderColor : ds.borderColor + "4D"
            })
            legend.chart.update()
        },
        handleLeave(evt, item, legend) {
            legend.chart.data.datasets.forEach(ds => {
                ds.borderColor = ds.borderColor.length === 9 ? ds.borderColor.slice(0, -2) : ds.borderColor
            })
            legend.chart.update()
        },
    },
}
</script>

<style scoped>
.component-container {
    display: flex;
    flex-direction: column;
}
.totalRowsStyle {
    display: flex;
    position: relative;
}
.chart-container {
    border: 1px solid #dee2e6;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 0;
    flex-grow: 1;
}
.chart-header {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem 2.5rem;
    padding-bottom: 0.5rem;
}
.measure-dropdown {
    width: 100%;
    max-width: 30rem;
}
.provider-dropdown {
    justify-self: end;
    width: 100%;
    max-width: 15rem;
}
.chart {
    flex-grow: 1;
    overflow: auto;
    position: relative;
    background-color: white;
    overflow: hidden;
}
.line-chart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>
