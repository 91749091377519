export const baseFields = {
    start_date: {
        label: "Konsumtionsperiod från",
        errorMsg: "",
        type: "start_date_channels",
        identifier: "start_date",
        default: null,
        required: true,
    },
    end_date: {
        label: "Konsumtionsperiod till",
        type: "end_date_channels",
        errorMsg: "",
        identifier: "end_date",
        default: null,
        required: true,
        editable: true,
    },
    providers: {
        label: "Kanaler",
        type: "provider-picker-channels",
        identifier: "provider_keys",
        title: "Kanaler",
        sites: [],
        channels: [],
        default: [],
    },
    grouptime: {
        label: "Visningsläge",
        identifier: "grouptime",
        type: "dropdown-grouptime-select",
        options: [
            { label: "Dag", id: "day" },
            { label: "7 dagar (rullande)", id: "sevendays" },
            { label: "Vecka", id: "week" },
        ],
    },
    target_group: {
        label: "Målgrupp",
        identifier: "target_group",
        type: "target_group_channels",
        config: {},
    },
}
