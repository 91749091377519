<template>
    <div class="dropdown-container">
        <b-dropdown size="sm" toggle-class="buttonclass" class="w-100 column drop-button" variant="outline-secondary" :text="label">
            <b-dropdown-text v-if="dropdownInfoText" style="width: 240px;">
                {{ dropdownInfoText }}
            </b-dropdown-text>
            <template #button-content>
                <span class="dropdown-label">{{ label }}</span>
            </template>
            <b-form-group class="checkboxcolumn">
                <b-form-checkbox v-model="allIsSelected" @change="selectAll" class="select-all-checkbox">
                    {{ allIsSelected ? "Avmarkera alla" : "Välj Alla" }}
                </b-form-checkbox>
                <b-form-checkbox-group v-model="selected" :options="optionsArray" @click.native="allIsSelected = false" class="selection-checkboxes" stacked></b-form-checkbox-group>
            </b-form-group>
        </b-dropdown>
    </div>
</template>

<script>
export default {
    name: "CheckboxDropdownOptions",
    props: {
        optionsArray: {
            default: [],
        },
        labelTypeName: {
            type: String,
            default: "",
        },
        initialSelection: {
            default: [],
        },
        dropdownInfoText: {
            default: "",
        },
    },
    data() {
        return {
            allIsSelected: false,
            selected: [],
        }
    },
    mounted() {
        this.selected = this.initialSelection
    },
    watch: {
        selected: function() {
            this.allIsSelected = this.selected.length == this.optionsArray.length ? true : false
            this.$emit("onInputChanged", this.selected)
        },
        optionsArray: function() {
            this.selected = this.initialSelection
        },
    },
    computed: {
        label: function() {
            let NumberOf = this.selected.length

            if (NumberOf == 0) return this.labelTypeName
            return NumberOf != 1 ? this.labelTypeName + " (" + NumberOf + " valda)" : this.selected + " (" + NumberOf + " vald)"
        },
    },
    methods: {
        selectAll(checked) {
            this.selected = checked ? this.optionsArray : []
        },
    },
}
</script>
<style scoped>
.dropdown-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
}

.selection-checkboxes {
    margin: 0 1.25rem;
}
.checkboxcolumn >>> .custom-checkbox {
    display: flex;
    margin: 0 1.5rem;
}
</style>
<style>
.checkboxcolumn > div > div > .custom-checkbox label,
.checkboxcolumn > div > div .custom-control-label {
    display: flex;
    align-items: center;
}
</style>
