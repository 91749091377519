<template>
    <section :class="bodyClass">
        <div class="search-settings">
            <ExportDialog @ok="selectExportMode" :dialogId="exportDialogId" getter="getChannelsExportQuery" setter="setChannelsExportQuery" />
            <div v-if="channelsInitStatus == 'LOADING'">
                <div v-if="loadingFormData" class="text-center mb-2">
                    <b-progress :value="loadingFormDataValue" show-progress class="m-5" />
                </div>
            </div>
            <div class="failedRequest" v-if="channelsInitStatus == 'FAILED'">Kan ej returnera resultat pga ett serverfel.</div>
            <div v-if="channelsInitStatus == 'LOADED'">
                <b-form>
                    <b-form-row @keyup.enter="onEnter">
                        <FormElement
                            v-for="(fieldData, key) in formFields"
                            :key="key"
                            :fieldData="fieldData"
                            getter="getChannelsQuery"
                            setter="setChannelsQuery"
                            providersSetter="setChannelsQueryProviders"
                            :latestDate="$store.getters.getPublishedToDate"
                            mutationSetEnddate="setChannelsEnddate"
                            mutationSetStartdate="setChannelsStartdate"
                            @onDisableConsumtionDateTo="disableConsumtionDateTo"
                            @onEnableConsumtionDateTo="enableConsumtionDateTo"
                            class="mb-3"
                            parentName="channels"
                        />
                    </b-form-row>
                </b-form>

                <b-form-row class="mb-2 mt-3">
                    <p v-html="displayStringForFilter" class="ml-1"></p>
                    <b-col class="justify-content-end d-flex align-items-end" cols="12">
                        <div class="ml-2">
                            <b-button type="submit" class="custom-border" variant="light" size="sm" @click="clearAllFields">
                                <b-icon icon="arrow-90deg-left"></b-icon>
                                Rensa sökval
                            </b-button>
                        </div>
                        <div>
                            <b-button type="submit" class="float-right searchbutton ml-2" variant="primary" size="sm" @click="submitSearch" :disabled="disableRunBtn">
                                <b-icon v-if="!loadingSearchResults" icon="arrow-repeat"></b-icon>
                                {{ loadingSearchResults ? "Arbetar..." : "Kör" }}
                            </b-button>
                        </div>
                    </b-col>
                    <p class="font-weight-bold" v-if="message != ''">{{ message }}</p>
                </b-form-row>
                <div v-if="!TargetGroupExists">
                    <div class="text-center mb-2">
                        <div>
                            <small class="text-danger"><strong>Du måste välja målgrupp under Målgrupper / Mått.</strong></small>
                        </div>
                    </div>
                </div>
                <div v-if="exceededConsumptionPeriodLimit">
                    <div class="text-center mb-2">
                        <div>
                            <small class="text-danger"><strong>Du får max välja 60 dagar som konsumtionsperiod.</strong></small>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <div class="tab-section">
            <b-tabs nav-wrapper-class="tab-nav" :content-class="tabContentContainer" v-model="tabIndex" :class="tabContainerClass">
                <b-tab title="Lista" active @click="showChartStyle(false), (tabName = 'list')">
                    <Spinner v-if="status == 'LOADING'" loadingMsg="Laddar resultat..." />
                    <div v-else-if="status == 'EMPTY_RESULT'">Hittade inget för aktuell sökning</div>
                    <div v-else-if="status == 'LOADED'">
                        <ResultTableMainChannel
                            @onSumButtonClicked="eventTriggerSumTab()"
                            @onChartButtonClicked=";(tabIndex = 2), showChartStyle(true), (tabName = 'list')"
                            :sortBy.sync="mainSortBy"
                            :sortDesc.sync="mainSortDesc"
                            :disableSumClick="disableSumClick"
                        />
                    </div>
                    <div class="failedRequest" v-else-if="status == 'FAILED'">Kan ej returnera resultat pga ett serverfel.</div>
                    <div v-else-if="status == 'VALIDATION_FAILED'" class="text-danger">
                        <span v-bind:key="index" v-for="(error, index) in formErrors" class="d-block">
                            {{ error }}
                        </span>
                    </div>
                </b-tab>
                <b-tab title="Summering" @click="fetchChannelsSummary" v-if="config_ShowSummaryChannel" :disabled="disableSumClick">
                    <Spinner v-if="statusSummary == 'LOADING'" loadingMsg="Laddar resultat..." />
                    <div v-else-if="statusSummary == 'EMPTY_RESULT'">Hittade inget för aktuell sökning</div>
                    <div v-else-if="statusSummary == 'LOADED'">
                        <ResultTableSumChannel :sortBy.sync="sumSortBy" :sortDesc.sync="sumSortDesc" />
                    </div>
                    <div class="failedRequest" v-else-if="statusSummary == 'FAILED'">Kan ej returnera resultat pga ett serverfel.</div>
                    <div v-else-if="statusSummary == 'VALIDATION_FAILED'" class="text-danger">
                        <span v-bind:key="index" v-for="(error, index) in formErrors" class="d-block">
                            {{ error }}
                        </span>
                    </div>
                </b-tab>
                <b-tab title="Diagram" @click="showChartStyle(true), (tabName = 'chart')" class="chart-content" :disabled="status !== 'LOADED'">
                    <Spinner v-if="status == 'LOADING'" loadingMsg="Laddar diagram..." />
                    <div v-else-if="status == 'EMPTY_RESULT'">Hittade inget för aktuell sökning</div>
                    <ResultDiagramMainChannel v-else-if="status == 'LOADED'" class="line-chart" />
                    <div class="failedRequest" v-if="status == 'FAILED'">Kan ej returnera resultat pga ett serverfel.</div>
                    <div v-else-if="status == 'VALIDATION_FAILED'" class="text-danger">
                        <span v-bind:key="index" v-for="(error, index) in formErrors" class="d-block">
                            {{ error }}
                        </span>
                    </div>
                </b-tab>
                <template v-slot:tabs-end>
                    <b-button :disabled="loadingExportResults" class="ml-auto" size="sm" variant="success" @click="userClickedExport" v-if="status === 'LOADED' && tabName === 'list'">
                        <b-icon v-if="!loadingExportResults" icon="arrow-repeat" />
                        {{ loadingExportResults ? "Arbetar..." : "Exportera" }}
                    </b-button>
                    <b-button :disabled="loadingExportResults" class="ml-auto" size="sm" variant="success" @click="userClickedExport" v-if="statusSummary == 'LOADED' && tabName === 'sum'">
                        <b-icon v-if="!loadingExportResults" icon="arrow-repeat" />
                        {{ loadingExportResults ? "Arbetar..." : "Exportera" }}
                    </b-button>
                </template>
            </b-tabs>
        </div>
    </section>
</template>

<script>
import FormElement from "@/components/Shared/FormElement/FormElement"
import Spinner from "@/components/Shared/Spinner/Spinner"
import { baseFields } from "./formData"
import { validationRules } from "./channelValidationRules"
import inputValidation from "@/mixins/inputValidation"
import { mapState } from "vuex"
import * as Actions from "@/store/modules/channels/action-types"
import * as Mutations from "@/store/modules/channels/mutation-types"
import { download, dateToyymmddFormat, scrollToBottom } from "../../../api/utils.js"
import dayjs from "dayjs"
import ResultTableMainChannel from "@/components/Layout/Channels/ResultTableMainChannel"
import ResultDiagramMainChannel from "@/components/Layout/Channels/ResultDiagramMainChannel"
import ResultTableSumChannel from "@/components/Layout/Channels/ResultTableSumChannel"
import ExportDialog from "@/components/Shared/FormElement/Dialogs/ExportDialog"
import globalConfig from "../../../mixins/globalConfig"

export default {
    name: "Channels",
    components: {
        FormElement,
        Spinner,
        ResultTableMainChannel,
        ResultDiagramMainChannel,
        ResultTableSumChannel,
        ExportDialog,
    },
    mixins: [inputValidation, globalConfig],
    data() {
        return {
            loadingFormData: true,
            loadingFormDataValue: 0,
            loadingSearchResults: false,
            formFields: baseFields,
            columnsField: {
                config: { options: [] },
                identifier: "columns",
                label: "Kolumner",
            },
            selectAll: false,
            calledGetChannels: false,
            tabName: "list",
            targetLabel: "",
            message: "",
            formErrors: [],
            loadingExportResults: false,
            tabIndex: 0,
            exportDialogId: "sites-export-modal",
            bodyClass: "body",
            tabContainerClass: "",
            tabContentContainer: "mt-3 table-content-container",
            mainSortBy: "providerName",
            mainSortDesc: false,
            sumSortBy: "providerName",
            sumSortDesc: false,
        }
    },
    watch: {
        "$store.getters.getPublishedToDate"(publishedToDate) {
            this.setStartAndEndDate(publishedToDate)
        },
        "$store.getters.getChannelsQuery.start_date"() {
            let endDate = this.$store.getters.getChannelsQuery.end_date
            let startDate = this.$store.getters.getChannelsQuery.start_date

            let validStartDate = dayjs(startDate, "YYYY-MM-DD").format("YYYY-MM-DD") === startDate
            let validEndDate = dayjs(endDate, "YYYY-MM-DD").format("YYYY-MM-DD") === endDate
            if (!validEndDate || !validStartDate) {
                return
            } else if (this.$store.getters.getChannelsQuery.grouptime === "sevendays") {
                let startDateDayjsObj = dayjs(startDate, "YYYY-MM-DD")
                let newEndDate = startDateDayjsObj.add(6, "day").format("YYYY-MM-DD")
                this.$store.commit(Mutations.setChannelsEnddate, newEndDate)
            } else if (this.$store.getters.getChannelsQuery.grouptime === "week" && startDate > endDate) {
                let weekday = require("dayjs/plugin/weekday")
                dayjs.extend(weekday)
                let endDateSunday = dayjs(startDate)
                    .weekday(6)
                    .format("YYYY-MM-DD")
                this.$store.commit(Mutations.setChannelsEnddate, endDateSunday)
            } else if (startDate > endDate) {
                this.$store.commit(Mutations.setChannelsEnddate, startDate)
            }
        },
        "$store.getters.getChannelsQuery.end_date"() {
            let endDate = this.$store.getters.getChannelsQuery.end_date
            let startDate = this.$store.getters.getChannelsQuery.start_date

            let validStartDate = dayjs(startDate, "YYYY-MM-DD").format("YYYY-MM-DD") === startDate
            let validEndDate = dayjs(endDate, "YYYY-MM-DD").format("YYYY-MM-DD") === endDate
            if (!validEndDate || !validStartDate) {
                return
            } else if (this.$store.getters.getChannelsQuery.grouptime === "week" && startDate > endDate) {
                let weekday = require("dayjs/plugin/weekday")
                dayjs.extend(weekday)
                let startDateMonday = dayjs(endDate)
                    .weekday(0)
                    .format("YYYY-MM-DD")
                this.$store.commit(Mutations.setChannelsStartdate, startDateMonday)
            } else if (startDate > endDate) {
                this.$store.commit(Mutations.setChannelsStartdate, endDate)
                return
            }
        },
        "$store.getters.getChannelsQuery": {
            deep: true,
            handler() {
                this.resetFailedOrEmptyState()
            },
        },
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
        this.init()
        let customParseFormat = require("dayjs/plugin/customParseFormat")
        dayjs.extend(customParseFormat)
    },
    computed: {
        disableSumClick() {
            let disableSumClick = false
            let getters = this.$store.getters
            if (this.status !== "LOADED" || !getters.getChannelsHaveSelectedRow || getters.getChannelsExportQuery.grouptime == "sevendays" || getters.getChannelsExportQuery.grouptime == "week")
                disableSumClick = true
            return disableSumClick
        },
        disableRunBtn() {
            let disableRunBtn = false
            let errorMsgExists = Object.values(this.formFields).some(function(field) {
                if (field.errorMsg) {
                    let errorMsg = field.errorMsg.replace(/^\s+|\s+$/gm, "")
                    if (errorMsg) return true
                }
            })
            let visibleChosenColumns = this.$store.getters.getChannelsQuery.columns
            if (visibleChosenColumns.length == 0 || this.loadingSearchResults || !this.TargetGroupExists || this.exceededConsumptionPeriodLimit || errorMsgExists) disableRunBtn = true
            return disableRunBtn
        },
        displayStringForFilter() {
            const query = this.$store.getters.getChannelsQuery
            let text = `<b>Datum från:</b> ${query.start_date}, <b>Datum till:</b> ${query.end_date}`
            let dayparts = 0
            let targetGroupCount = 0
            if (query.provider_keys) {
                let channelCount = query.provider_keys.length
                if (channelCount > 0) text += `, <b>Kanaler:</b> ${channelCount} valda`
            }
            if (query.weekdays) text += query.weekdays.length > 0 ? `, <b>Veckodagar:</b> ${query.weekdays.length} valda` : ""
            if (localStorage.getItem("dayparts")) {
                dayparts = JSON.parse(localStorage.getItem("dayparts")).getChannelsQuery.length
                text += dayparts > 0 ? `, <b>Tidsslotar:</b> ${dayparts} valda` : ""
            }
            let selectedTargetGroups = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : {}
            if (selectedTargetGroups["getChannelsQuery"] !== undefined) {
                targetGroupCount = selectedTargetGroups["getChannelsQuery"].length
                text += targetGroupCount > 0 ? `, <b>Målgrupper:</b> ${targetGroupCount} valda` : ""
            }
            return text
        },
        statusSummary() {
            return this.$store.getters.getChannelsSummaryResults.status
        },
        disableSelectAllBtn() {
            const allStarSelected = this.$store.getters.getChannelsAllWithStarSelected
            if (allStarSelected) return false
            else return true
        },
        result() {
            return this.$store.getters.getChannelsResults
        },
        status() {
            return this.$store.getters.getChannelsResults.status
        },
        // lite state från vuex
        ...mapState({
            channelsInitStatus: ({ channels }) => channels.channelsInitStatus,
            query: ({ channels }) => channels.channelsQuery,
        }),
        hiddenColumns() {
            return this.$store.getters.getChannelsColumnTypes.filter(x => x.hidden === true)
        },
        TargetGroupExists() {
            this.$store.getters.getChannelsQuery.target_group

            let storedTargets = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : []
            if (storedTargets) {
                if (typeof storedTargets.getChannelsQuery === "undefined") storedTargets = null
            }

            return storedTargets != null && storedTargets.getChannelsQuery.length > 0 ? true : false
        },
        exportQuery() {
            return this.$store.getters.getChannelsExportQuery
        },
        exceededConsumptionPeriodLimit() {
            const maxMmsDays = 60
            let startDate = this.$store.getters.getChannelsQuery.start_date
            let endDate = dayjs(this.$store.getters.getChannelsQuery.end_date)
            let chosenMmsDays = dayjs(endDate).diff(startDate, "day") + 1
            return chosenMmsDays > maxMmsDays
        },
    },
    methods: {
        disableConsumtionDateTo() {
            this.formFields.end_date.editable = false
        },
        enableConsumtionDateTo() {
            this.formFields.end_date.editable = true
        },
        eventTriggerSumTab() {
            this.tabIndex = 1
            this.fetchChannelsSummary()
        },
        async fetchChannelsSummary() {
            this.showChartStyle(false)
            this.tabName = "sum"
            await this.$store.dispatch(Actions.executeChannelsSummaryQuery)
        },
        addTdClassToLastSumColumns(columns) {
            let passedColumnBeforeMeasure = false
            let indexOfColumnBeforeMeasure = 0
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].label == this.$store.getters.getChannelsResults.data.summary.columnBeforeMeasures || passedColumnBeforeMeasure) {
                    columns[i].tdClass = "tdSummaryDataClass"
                    if (!passedColumnBeforeMeasure) indexOfColumnBeforeMeasure = i
                    passedColumnBeforeMeasure = true
                }
            }
            if (passedColumnBeforeMeasure && indexOfColumnBeforeMeasure > 0) columns[indexOfColumnBeforeMeasure - 1].tdClass = "columnBeforeSummaryData"
            return columns
        },
        clearAllFields() {
            //Remove targetgroups from local storage
            var storedTargetGroups = JSON.parse(localStorage.getItem("targetGroups"))
            if (storedTargetGroups != null) {
                storedTargetGroups.getChannelsQuery = []
                localStorage.setItem("targetGroups", JSON.stringify(storedTargetGroups))
            }
            let systemToDate = new Date(this.$store.getters.getMetadataProgramToDate)
            this.$store.commit(Mutations.setChannelsQuery, {
                ...this.$store.getters.getChannelsQuery,
                start_date: dateToyymmddFormat(systemToDate.setDate(systemToDate.getDate())),
                end_date: dateToyymmddFormat(systemToDate.setDate(systemToDate.getDate())),
                channels: [],
                provider_keys: [],
                target_group: [],
                columns: this.$store.getters.getChannelsDefaultColumns,
            })
        },
        excludeSelectedRows() {
            this.selectAll = false
        },
        getPanelDay(startDate, endDate) {
            let panelDay = new Date((startDate.getTime() + endDate.getTime()) / 2)
            let difference = endDate.getTime() - startDate.getTime()
            let days = Math.ceil(difference / (1000 * 3600 * 24)) + 1
            let isOdd = days % 2
            if (isOdd == 0) {
                panelDay.setDate(panelDay.getDate() + 1)
                return panelDay
            }
            return panelDay
        },
        async init() {
            try {
                await this.$store.commit(Mutations.setChannelsInitStatus, "LOADING")

                this.setStartAndEndDate(this.$store.getters.getPublishedToDate)
                this.loadingFormDataValue = 33
                await this.getProviders()
                // Measures:
                const measures = await this.$store.dispatch(Actions.getChannelsMeasures)
                this.formFields.target_group.config.measures = [...measures]
                this.loadingFormDataValue = 66
                // COLUMNTYPES:
                const columns = await this.$store.dispatch(Actions.getChannelsColumnTypes)
                this.loadingFormDataValue = 100
                this.columnsField.config.options = [...columns]
                await this.$store.commit(Mutations.setChannelsColumnTypes, columns)
                await this.$store.commit(
                    Mutations.setChannelsDefaultColumns,
                    columns.filter(c => c.default_selection).map(c => c.value)
                )

                let updatedQuery = {
                    ...this.$store.getters["getChannelsQuery"],
                    ["columns"]: this.$store.getters.getChannelsDefaultColumns,
                }
                this.$store.commit("setChannelsQuery", updatedQuery)
                // viktigt med await för att vue ska uppdatera våra komponenter
                // status måste vara = LOADED för att de ska visas och de refreshas då när vi sätter formFields,
                // om de inte visas så refreshas de icke...
                await this.$store.commit(Mutations.setChannelsInitStatus, "LOADED")
            } catch (error) {
                await this.$store.commit(Mutations.setChannelsInitStatus, "FAILED")
            }
        },

        async submitSearch() {
            this.tabIndex = 0
            this.selectAll = false
            if (!this.TargetGroupExists || this.exceededConsumptionPeriodLimit) return
            validationRules(this)
            const hasError = this.validateForm()
            if (hasError) {
                scrollToBottom()
                this.$store.commit(Mutations.setChannelsResults, {
                    status: "VALIDATION_FAILED",
                })
                if (this.formFields.start_date.hasError || this.formFields.end_date.hasError) this.formErrors.push("Vänligen fyll i start- och slutdatum korrekt.")
                return
            }
            this.$store.commit("setChannelsHaveSelectedRow", false)
            this.tabName = "list"
            this.loadingSearchResults = true
            try {
                let query = {
                    ...this.$store.getters.getChannelsExportQuery,
                    sortBy: "providerName",
                    sortByDesc: false,
                }
                this.$store.commit(Mutations.setChannelsExportQuery, query)
                this.mainSortBy = "providerName"
                this.mainSortDesc = true
                this.sumSortBy = "providerName"
                this.sumSortDesc = true
                await this.$store.dispatch(Actions.executeChannelsQuery)
            } catch (err) {
                this.loadingSearchResults = false
            }
            this.loadingSearchResults = false
        },
        setStartAndEndDate(publishedToDate) {
            if (!publishedToDate) return
            let previousDate = this.$store.getters.getChannelsQuery
            //only set enddate to defaultdate when endate is not changed
            if (!previousDate.end_date) this.$store.commit(Mutations.setChannelsEnddate, this.$store.getters.getMetadataProgramToDate)
            if (!previousDate.start_date) this.$store.commit(Mutations.setChannelsStartdate, this.$store.getters.getMetadataProgramToDate)
        },
        async getProviders() {
            const providers = await this.$store.dispatch(Actions.getChannelsProviders)
            const sites = providers.filter(x => x.provider_type == "SITE" && x.exists_content)
            const channels = providers
                .filter(x => x.provider_type == "CHANNEL" && x.exists_content)
                .reduce((p, c) => {
                    return { ...p, [c.provider_group]: [...(p[c.provider_group] ?? []), c] }
                }, {})

            const newFields = {
                ...this.formFields,
                providers: { ...this.formFields.providers, sites: sites, channels: channels },
            }

            this.formFields = newFields
        },
        onEnter() {
            this.submitSearch()
        },
        userClickedExport() {
            this.$bvModal.show(this.exportDialogId)
        },
        selectExportMode() {
            //redirect to regular or summary export
            if (this.tabName === "list") {
                this.exportResult()
            } else {
                this.exportSumResult()
            }
        },
        async exportResult() {
            this.loadingExportResults = true
            let exportToCsv
            try {
                let query = {
                    ...this.$store.getters.getChannelsExportQuery,
                    sortBy: this.mainSortBy,
                    sortByDesc: this.mainSortDesc,
                }
                await this.$store.commit(Mutations.setChannelsExportQuery, query)
                exportToCsv = await this.$store.dispatch(Actions.executeExportChannelsQuery)
            } catch (error) {
                this.showMsg("Serverfel", "Det gick inte att exportera kanaler", "danger")
                this.loadingExportResults = false
                return
            }
            let fileName = ""
            let fileSuffix = this.exportQuery.include_detail_rows ? ".csv" : "_Exklusive_Detaljrader.csv"
            if (this.$store.getters.getExcelExportFilePreName == undefined || this.$store.getters.getExcelExportFilePreName == "") {
                fileName = `TotalanalysKanaler_${this.query.start_date}_${this.query.end_date}${fileSuffix}`
            } else {
                fileName = `${this.$store.getters.getExcelExportFilePreName} TotalanalysKanaler_${this.query.start_date}_${this.query.end_date}${fileSuffix}`
            }
            download(exportToCsv, fileName, null)
            this.loadingExportResults = false
        },
        async exportSumResult() {
            this.loadingExportResults = true
            let exportToCsv
            try {
                let query = {
                    ...this.$store.getters.getChannelsExportQuery,
                    sortBy: this.sumSortBy,
                    sortByDesc: this.sumSortDesc,
                }
                await this.$store.commit(Mutations.setChannelsExportQuery, query)
                exportToCsv = await this.$store.dispatch(Actions.executeExportChannelsSummaryQuery)
            } catch (error) {
                this.showMsg("Serverfel", "Det gick inte att exportera summering av kanaler", "danger")
                this.loadingExportResults = false
                return
            }
            let fileName = ""
            let fileSuffix = this.exportQuery.include_detail_rows ? ".csv" : "_Exklusive_Detaljrader.csv"
            if (this.$store.getters.getExcelExportFilePreName == undefined || this.$store.getters.getExcelExportFilePreName == "") {
                fileName = `TotalanalysKanalerSummering_${this.query.start_date}_${this.query.end_date}${fileSuffix}`
            } else {
                fileName = `${this.$store.getters.getExcelExportFilePreName} TotalanalysKanalerSummering_${this.query.start_date}_${this.query.end_date}${fileSuffix}`
            }
            download(exportToCsv, fileName, null)
            this.loadingExportResults = false
        },
        showMsg(title, msg, variant) {
            this.$bvToast.toast(msg, {
                title: title,
                variant: variant,
                solid: true,
                autoHideDelay: 5000,
            })
        },
        showChartStyle(show) {
            if (show === true) {
                this.bodyClass = "chart-body"
                this.tabContainerClass = "chart-tab-container"
                this.tabContentContainer = "mt-3 chart-content-container"
            } else {
                this.bodyClass = "body"
                this.tabContainerClass = ""
                this.tabContentContainer = "mt-3"
            }
        },
        resetFailedOrEmptyState() {
            if (this.$store.getters.getChannelsResults.status == "FAILED" || this.$store.getters.getChannelsResults.status == "EMPTY_RESULT") {
                const updatedQuery = {
                    ...this.$store.getters.getChannelsResults,
                    status: "NOT_LOADED",
                }
                this.$store.commit(Mutations.setChannelsResults, updatedQuery)
            } else if (this.$store.getters.getChannelsSummaryResults.status == "FAILED" || this.$store.getters.getChannelsSummaryResults.status == "EMPTY_RESULT") {
                const updatedQuery = {
                    ...this.$store.getters.getChannelsSummaryResults,
                    status: "NOT_LOADED",
                }
                this.$store.commit(Mutations.setChannelsSummaryResults, updatedQuery)
            }
        },
    },
}
</script>

<style>
.body {
    background-color: white;
    padding: 20px 30px;
}
.chart-body {
    background-color: white;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.tab-section {
    max-height: 100%;
    flex: 1;
}
.chart-tab-container {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.chart-content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}
.chart-content {
    background-color: white;
    min-height: 25rem;
    flex-grow: 1;
    position: relative;
}
.totalRowsStyle {
    margin-left: 10px;
    margin-top: 8px;
}
.searchbutton {
    width: 8vw;
}
.label-text {
    font-weight: 713;
    font-size: 11px;
    color: #495057;
    float: left;
    margin-bottom: 0.35rem;
}
.nav-tabs {
    font-size: 80%;
    font-weight: 400;
    align-items: center;
}
.measure-header {
    text-align: right;
    line-height: 1.1;
}
.failedRequest {
    color: red;
}
</style>
