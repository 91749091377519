<template>
    <div>
        <label class="totalRowsStyle float-left">
            {{ getRowCount }}
        </label>
        <b-table
            thead-class="stickyfancytableheader"
            :fields="fields"
            class="tablemainresultclass"
            :per-page="visibleRows"
            :current-page="currentPage"
            select-mode="multi"
            :items="levelOneSitesWithLevelTwoSites"
            bordered
            small
            :busy="loading"
            striped
            :head-variant="'dark'"
            :sort-compare-options="{ numeric: true, sensitivity: 'base' }"
            sort-compare-locale="sv"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @sort-changed="onSort"
        >
            <template v-slot:top-row>
                <td class="summary-row" v-for="field in fields" :key="field.key" :class="field.tdClass">
                    {{ getSummaryRowData[field.key] }}
                </td>
            </template>
            <template v-slot:cell(plussign)="row">
                <div class="plusminuscontainer" v-if="row.item.numberOfLvl2Objects > 1">
                    <div @click="clickedPlusMinusSign(row)" class="plusminusbutton">
                        <font-awesome-icon v-if="!row.item._showDetails" icon="fa fa-plus-circle" class="plusminusicon" />
                        <font-awesome-icon v-else icon="fa fa-minus-circle fa-1x" class="plusminusicon" />
                        <div class="plusminusline"></div>
                    </div>
                </div>
                <div v-else-if="row.item.numberOfLvl2Objects === 1">
                    {{ setLevelOneSiteToLevelTwoSite(row.item) }}
                </div>
            </template>
            <template v-slot:head()="field">
                <span class="measureheader" v-html="getTableFieldLabel(field)"></span>
            </template>
            <template v-slot:cell(providerName)="row">
                <span v-html="row.item.providerName"></span>
            </template>
            <template #row-details="row">
                <b-table-lite
                    class="mb-0 detail-row-table"
                    :class="groupClassOddEven(row.index)"
                    :tbody-tr-class="detailRowClassOddEven(row.index)"
                    borderless
                    thead-class="hideheader"
                    :fields="fields"
                    :items="row.item.levelTwoSites"
                >
                    <template v-slot:cell(chosen)="row">
                        <b-form-checkbox class="choose-checkbox-div" v-model="row.item.chosen"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(providerName)="row">
                        <span v-html="row.item.providerName"></span>
                    </template>
                </b-table-lite>
            </template>
        </b-table>
        <b-pagination
            class="justify-content-center"
            v-model="currentPage"
            :total-rows="$store.getters.getChannelsSummaryResults.data.levelOneSitesWithLevelTwoSites.length"
            :per-page="visibleRows"
            aria-controls="my-table"
        ></b-pagination>
    </div>
</template>
<script>
export default {
    name: "ResultTableSumChannel",
    props: ["sortBy", "sortDesc"],
    data() {
        return {
            selectAll: false,
            selectClickedRow: true,
            visibleRows: 50,
            callOnce: false,
            currentPage: 1,
            loading: false,
        }
    },
    computed: {
        getRowCount() {
            let totalLength = this.$store.getters.getChannelsSummaryResults.data.levelOneSitesWithLevelTwoSites.length - 1
            if (this.visibleRows > totalLength) {
                return `${totalLength}/${totalLength}`
            }
            return `${this.visibleRows}/${totalLength}`
        },
        levelOneSitesWithLevelTwoSites() {
            let rows = [...this.$store.getters.getChannelsSummaryResults.data.levelOneSitesWithLevelTwoSites]
            rows.shift()
            return rows
        },
        fields() {
            let fields = [...this.$store.getters.getChannelsSummaryResults.data.columns]
            fields.unshift({ key: "plussign", tdClass: "plusminussigntd", thClass: "plusminussignth", label: "" })
            for (const field of fields) {
                if (field.key === "title") {
                    field.thClass = "titleheadercolumn"
                } else if (field.key === "date") {
                    field.thClass = "date-th"
                } else if (field.key.includes("measure")) {
                    field.sortDirection = "desc"
                }
            }
            return fields
        },
        getSummaryRowData() {
            let summaryRow = [...this.$store.getters.getChannelsSummaryResults.data.levelOneSitesWithLevelTwoSites][0]
            summaryRow.numberOfLvl2Objects = []
            let secondColumn = this.fields[1].key
            summaryRow[secondColumn] = "BERÄKNING"
            return summaryRow
        },
    },
    methods: {
        onSort(sortInfo) {
            this.$emit("update:sortBy", sortInfo.sortBy)
            this.$emit("update:sortDesc", sortInfo.sortDesc)
        },
        setLevelOneSiteToLevelTwoSite(row) {
            if (!Array.isArray(row.levelTwoSites) || row.levelTwoSites.length == 0) {
                return
            }
            let levelTwoSite = row.levelTwoSites
            let columnKeys = this.$store.getters.getChannelsResults.data.columns.map(column => column.key)
            for (let i = 0; i < columnKeys.length; i++) {
                row[columnKeys[i]] = levelTwoSite[0][columnKeys[i]]
            }
        },
        clickedPlusMinusSign(row) {
            this.$set(row.item, "_showDetails", !row.item._showDetails)
            this.selectClickedRow = false
        },
        detailRowClassOddEven(index) {
            return index % 2 != 0 ? "row-even detail-row" : "row-odd detail-row"
        },
        groupClassOddEven(index) {
            return index % 2 != 0 ? "group-even" : "group-odd"
        },
        getTableFieldLabel(field) {
            if (!field.field.key.includes("measure")) {
                return field.field.label
            }
            return `${field.field.header} <span class="measureagewrapper">${field.field.subHeader}</span>`
        },
    },
}
</script>
<style>
.plusminussignth {
    width: 2.2rem;
}
.table tbody tr .plusminussigntd {
    vertical-align: inherit;
    font-size: 0.9rem;
}
.choose-checkbox-th {
    width: 3rem;
}
</style>
<style scoped>
::v-deep .summary-row {
    background-color: #ddd !important;
    font-weight: bold;
    border-bottom: 5px solid #737c84;
}
.summary-row .plusminusclass {
    display: none;
}
.summary-row .plusminusicon {
    display: none;
}
::v-deep .tablemainresultclass th {
    font-size: 0.8rem;
    background-color: #337ab7 !important;
    border-color: #bfd5e8 !important;
    border-top: white;
    border-width: 2px 1px 2px 1px !important;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}
.resultgrey {
    background-color: #6c757d;
}
table.tablemainresultclass {
    text-align: left;
    background-color: white;
    border-width: 0px 1px 1px 1px;
}
.slimth {
    line-height: 1;
}
.plusminusicon {
    color: #337ab7;
    font-size: 1.25em;
    margin: auto;
    display: block;
}
::v-deep .detail-row {
    background-color: transparent !important;
}
::v-deep .detail-row td {
    font-size: 0.65rem;
}
/* table is using bootstrap striped attribute for main rows and index based css for detail rows*/
::v-deep .row-even {
    background-color: #ffffff;
}
::v-deep .row-odd {
    background-color: #f2f2f2;
}
::v-deep .group-even {
    background-color: #f2f2f2;
}
::v-deep .group-odd {
    background-color: #ffffff;
}
::v-deep tbody tr td {
    border-bottom: none;
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
::v-deep .titleheadercolumn {
    width: 20%;
}
::v-deep .plusminusbutton {
    display: flex;
    position: relative;
    z-index: 2;
    padding: 0.4em 0;
    margin: -0.4em 0;
    cursor: pointer;
}
/* hiding table group borders */
::v-deep .detail-row-table tbody tr td {
    border: none;
}
::v-deep .b-table-has-details td:not(:last-child) {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-right: 1px solid rgba(255, 255, 255, 0);
}

::v-deep .b-table-has-details td:first-child {
    border-left: 1px solid #dee2e6;
    border-right: 0px solid #dee2e6;
}
::v-deep .b-table-has-details td:nth-child(2) {
    border-left: 0px solid #dee2e6;
    border-right: 0px solid #dee2e6;
}
/* constructing the hierarchy lines in the plussign column */
::v-deep .plusminussigntd {
    padding: 0;
    position: relative;
}
/* hiding the overflowing line behind transparent part of +/- icon */
::v-deep .plusminusbutton::before {
    content: "";
    background: white;
    z-index: -1;
    height: 37%;
    left: 12px;
    position: absolute;
    top: 32%;
    width: 10px;
}
::v-deep .b-table-has-details td:first-child::after {
    content: "";
    background: #a9b1b8;
    height: 42%;
    left: 16px;
    position: absolute;
    top: 60%;
    width: 0.5px;
    z-index: 1;
}
::v-deep .detail-row-table .plusminussigntd::before {
    content: "";
    background: #a9b1b8;
    width: 50%;
    left: 16px;
    position: absolute;
    top: 50%;
    height: 0.5px;
}
::v-deep .detail-row-table .plusminussigntd:first-child::after {
    content: "";
    background: #a9b1b8;
    height: 100%;
    left: 16px;
    position: absolute;
    top: -50%;
    width: 0.5px;
}
</style>
