<template>
    <div>
        <b-modal @ok="e => $emit('ok', e)" size="m" :centered="true" :id="dialogId" title="Exportera data" title-class="ml-auto" getter="getChannelsExportQuery" setter="setChannelsExportQuery">
            <div class="flex-grid">
                <div class="col textwrapper">
                    <label class="labelclass" for="switch_1">Detaljrader: </label>
                </div>
                <div class="col detailrowswitchwrapper">
                    <b-form-checkbox id="switch_1" v-model="detailrows" switch class="ml-2 d-inline-block"></b-form-checkbox>
                    <label class="labelclass" for="switch_1">{{ detailrows ? "Ja" : "Nej" }}</label>
                </div>
            </div>
            <div class="flex-grid">
                <div class="col textwrapper">
                    <label class="labelclass" for="switch_2">Rapporthuvud: </label>
                </div>
                <div class="col">
                    <b-form-checkbox id="switch_2" v-model="header" switch class="ml-2 d-inline-block"></b-form-checkbox>
                    <label class="labelclass" for="switch_2">{{ header ? "Ja" : "Nej" }}</label>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok }">
                <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide(dialogId)">
                    Avbryt
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Ok
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "ExportDialog",
    props: {
        dialogId: String,
        dialogTitle: {
            type: String,
            default: "Är du säker?",
        },
        getter: String,
        setter: String,
    },
    computed: {
        detailrows: {
            get() {
                return this.$store.getters[this.getter].include_detail_rows
            },
            set(val) {
                const updatedQuery = {
                    ...this.$store.getters[this.getter],
                    include_detail_rows: val,
                }
                return this.$store.commit(this.setter, updatedQuery)
            },
        },
        header: {
            get() {
                return this.$store.getters[this.getter].include_header
            },
            set(val) {
                const updatedQuery = {
                    ...this.$store.getters[this.getter],
                    include_header: val,
                }
                return this.$store.commit(this.setter, updatedQuery)
            },
        },
    },
}
</script>

<style scoped>
>>> .modal-body {
    width: 15rem;
    margin: auto;
}
.flex-grid {
    display: flex;
}
.detailrowswitchwrapper {
    padding-left: 1.9rem;
}
.textwrapper {
    max-width: 6.3rem;
    padding: 0px;
}
.form {
    display: inline-grid;
}
.labelclass {
    font-size: 1rem;
    margin-bottom: 0.2rem;
    margin-right: 0.2rem;
}
</style>
