<template>
    <div>
        <b-modal v-model="showAlertModal"
            ><div class="text-center">
                {{ alertModalMsg }}
            </div>
            <template #modal-footer>
                <b-button size="sm" variant="primary" @click="showAlertModal = false">
                    Ok
                </b-button>
            </template>
        </b-modal>
        <span class="text-danger mb-2 d-block" v-if="showSelectSpecificChannelError"
            >Dina målgrupper är justerade i svaret. Enbart målgruppen A3-99 är tillgänglig för räckvidd för enskilda kanalkombinationer.</span
        >
        <b-button class="mb-2 mr-sm-2 btn-responsive float-left" size="sm" variant="primary" @click="$emit('onSumButtonClicked')" :disabled="disableSumClick" v-if="config_ShowSummaryChannel">
            Ta markeringar till Summering
        </b-button>
        <b-button class="mb-2 btn-responsive float-left" size="sm" variant="primary" @click="$emit('onChartButtonClicked')">
            Visa samtliga rader som Diagram
        </b-button>
        <label class="totalRowsStyle float-left"> Svarsrader: {{ getRowCount }} </label>
        <b-table
            thead-class="stickyfancytableheader"
            :fields="fields"
            class="tablemainresultclass"
            :per-page="visibleRows"
            :current-page="currentPage"
            ref="selectableTable"
            :items="levelOneSitesWithLevelTwoSites"
            bordered
            small
            select-mode="range"
            selectable
            primary-key="index"
            striped
            :head-variant="'dark'"
            :sort-compare-options="{ numeric: true, sensitivity: 'base' }"
            sort-compare-locale="sv"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @sort-changed="onSort"
            @row-selected="rowSelected"
            @row-clicked="rowClicked"
        >
            <template v-slot:cell(plussign)="row">
                <div class="plusminuscontainer" v-if="row.item.numberOfLvl2Objects > 1">
                    <div @click="clickedPlusMinusSign(row)" class="plusminusbutton">
                        <font-awesome-icon v-if="!row.item._showDetails" icon="fa fa-plus-circle" class="plusminusicon" />
                        <font-awesome-icon v-else icon="fa fa-minus-circle fa-1x" class="plusminusicon" />
                        <div class="plusminusline"></div>
                    </div>
                </div>
                <div v-else-if="row.item.numberOfLvl2Objects === 1">
                    {{ setLevelOneSiteToLevelTwoSite(row.item) }}
                </div>
            </template>
            <template v-slot:cell(chosen)="row">
                <b-form-checkbox class="choose-checkbox-div" v-model="row.item.chosen" @change="onLvlOneCheckClick(row.item), checkSelection()"></b-form-checkbox>
            </template>
            <template v-slot:head()="field">
                <span class="measureheader" v-html="getTableFieldLabel(field)"></span>
            </template>
            <template v-slot:cell(providerName)="row">
                <span v-html="row.item.providerName"></span>
            </template>
            <template #row-details="row">
                <b-table-lite
                    class="mb-0 detail-row-table"
                    :class="groupClassOddEven(row.index)"
                    :tbody-tr-class="detailRowClassOddEven(row.index)"
                    borderless
                    thead-class="hideheader"
                    :fields="fields"
                    :items="row.item.levelTwoSites"
                >
                    <template v-slot:cell(chosen)="row">
                        <b-form-checkbox class="choose-checkbox-div" :disabled="true" v-model="row.item.chosen" @change="checkSelection()"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(providerName)="row">
                        <span v-html="row.item.providerName"></span>
                    </template>
                </b-table-lite>
            </template>
        </b-table>
        <b-pagination
            class="justify-content-center"
            v-model="currentPage"
            :total-rows="$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites.length"
            :per-page="visibleRows"
            aria-controls="my-table"
        ></b-pagination>
    </div>
</template>
<script>
import globalConfig from "@/mixins/globalConfig"
export default {
    name: "ResultTableMainChannel",
    props: ["sortBy", "sortDesc", "disableSumClick"],
    mixins: [globalConfig],
    data() {
        return {
            selectClickedRow: true,
            visibleRows: 50,
            callOnce: false,
            currentPage: 1,
            showAlertModal: false,
            alertModalMsg: "",
        }
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()

    },
    computed: {
        showSelectSpecificChannelError() {
            return this.$store.getters.getChannelsResults.showSelectSpecificChannelError
        },
        getRowCount() {
            let totalLength = this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites.length
            let previousRowsAndCurrentRows = this.visibleRows * this.currentPage
            if (previousRowsAndCurrentRows > totalLength) {
                return `${totalLength}/${totalLength}`
            }
            return `${previousRowsAndCurrentRows}/${totalLength}`
        },
        levelOneSitesWithLevelTwoSites() {
            return this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites
        },
        fields() {
            let fields = [...this.$store.getters.getChannelsResults.data.columns]
            fields.unshift({ key: "plussign", tdClass: "plusminussigntd", thClass: "plusminussignth", label: "" }, { key: "chosen", thClass: "choose-checkbox-th", label: "Valda" })
            for (const field of fields) {
                if (field.key.includes("measure")) {
                    field.sortDirection = "desc"
                }
            }
            return fields
        },
    },

    methods: {
        onSort(sortInfo) {
            this.$emit("update:sortBy", sortInfo.sortBy)
            this.$emit("update:sortDesc", sortInfo.sortDesc)
        },
        rowClicked(row){
            if(row.chosen){
                row.chosen = false;
                row.levelTwoSites.map(c => (c.chosen = false))
            }else{
                row.chosen = true;
                row.levelTwoSites.map(c => (c.chosen = true))
            }
            this.checkSelection();
        },
      rowSelected(row) {
                if (row.length === 1) {
                    row[0].chosen = true
                    row[0].levelTwoSites.map(c => (c.chosen = true))
                    this.levelOneSitesWithLevelTwoSites.forEach(element => {
                        if (row.[0].index != element.index) {
                            element.chosen = false
                            if(element.levelTwoSites){
                                element.levelTwoSites.map(c => (c.chosen = false))
                            }
                        }
                    })
                }
                if (row.length > 1) {

                     this.levelOneSitesWithLevelTwoSites.forEach(e => {
                          row.forEach(element => {
                              if (element.index == e.index) {
                               let validSelection =  this.validateSelection(element);
                               if(validSelection === false)
                                    this.clearSelection(element);
                               else{
                                  e.chosen = true
                                  e.levelTwoSites.map(c => (c.chosen = true))
                               }
                          }
                        })
                    })
                }
                this.checkSelection();

        },
        clearSelection(element){

                    element.chosen = false
                    if(element.levelTwoSites){
                        element.levelTwoSites.map(c => (c.chosen = false))
                    }
        },
        validateSelection(row){
                let chosenLvlOneChannels = this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites.filter(c => c.chosen)
                let chosenDates = chosenLvlOneChannels.map(c => c.date)
                const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
                var minDate = new Date(
                    chosenDates.reduce(function(a, b) {
                        return a < b ? a : b
                    })
                )
                var maxDate = new Date(
                    chosenDates.reduce(function(a, b) {
                        return a > b ? a : b
                    })
                )
                const diffDays = Math.round(Math.abs((minDate - maxDate) / oneDay)) + 1
                //prevent selection if the selected providernames are different from the one clicked
                let providerNamesDifferent = chosenLvlOneChannels.some(c => c.providerName !== row.providerName)
                if (providerNamesDifferent) this.alertModalMsg = "Du kan endast välja kanaler inom samma mediehus."
                else if (diffDays > 7) this.alertModalMsg = "Kan endast välja datum inom en 7 dagars period till summering."
                if (providerNamesDifferent || diffDays > 7) {
                    this.showAlertModal = true
                    row.chosen = false
                    return false;
                }
        },
        setLevelOneSiteToLevelTwoSite(row) {
            let levelTwoProgram = row.levelTwoSites
            let columnKeys = this.$store.getters.getChannelsResults.data.columns.map(column => column.key)
            for (let i = 0; i < columnKeys.length; i++) {
                row[columnKeys[i]] = levelTwoProgram[0][columnKeys[i]]
            }
        },
        onLvlOneCheckClick(row) {
            if (row.chosen) {
               this.validateSelection(row);
            }
            let levelTwoSites = row.levelTwoSites
            levelTwoSites.map(c => (c.chosen = row.chosen))
        },
        checkSelection() {

            let hasSelection = this.$store.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites
                .map(p => p.levelTwoSites)
                .flat()
                .some(l2 => {
                    return l2.chosen
                })
            this.$store.commit("setChannelsHaveSelectedRow", hasSelection)
        },
        clickedPlusMinusSign(row) {
              event.stopPropagation();
            this.$set(row.item, "_showDetails", !row.item._showDetails)
            this.selectClickedRow = false
        },
        detailRowClassOddEven(index) {
            return index % 2 == 0 ? "row-even detail-row" : "row-odd detail-row"
        },
        groupClassOddEven(index) {
            return index % 2 == 0 ? "group-even" : "group-odd"
        },
        getTableFieldLabel(field) {
            if (!field.field.key.includes("measure")) {
                return field.field.label
            }
            return `${field.field.header} <span class="measureagewrapper">${field.field.subHeader}</span>`
        },
    },
}
</script>
<style>
.plusminussignth {
    width: 2.2rem;
}
.table tbody tr .plusminussigntd {
    vertical-align: inherit;
    font-size: 0.9rem;
}

.choose-checkbox-th {
    width: 3rem;
}
</style>
<style scoped>
.choose-checkbox-div {
    padding-left: 2.15rem;
}
::v-deep .tablemainresultclass th {
    font-size: 0.8rem;
    background-color: #337ab7 !important;
    border-color: #bfd5e8 !important;
    border-top: white;
    border-width: 2px 1px 2px 1px !important;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}
.resultgrey {
    background-color: #6c757d;
}
table.tablemainresultclass {
    text-align: left;
    background-color: white;
    border-width: 0px 1px 1px 1px;
}
.plusminusicon {
    color: #337ab7;
    font-size: 1.25em;
    margin: auto;
    display: block;
}
::v-deep .detail-row {
    background-color: transparent !important;
}
::v-deep .detail-row td {
    font-size: 0.65rem;
}
/* table is using bootstrap striped attribute for main rows and index based css for detail rows*/
::v-deep .row-even {
    background-color: #ffffff;
}
::v-deep .row-odd {
    background-color: #f2f2f2;
}
::v-deep .group-even {
    background-color: #f2f2f2;
}
::v-deep .group-odd {
    background-color: #ffffff;
}
::v-deep tbody tr td {
    border-bottom: none;
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
::v-deep .titleheadercolumn {
    width: 20%;
}
::v-deep .plusminusbutton {
    display: flex;
    position: relative;
    z-index: 2;
    padding: 0.4em 0;
    margin: -0.4em 0;
    cursor: pointer;
}
/* hiding table group borders */
::v-deep .detail-row-table tbody tr td {
    border: none;
}
::v-deep .b-table-has-details td:not(:last-child) {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-right: 1px solid rgba(255, 255, 255, 0);
}

::v-deep .b-table-has-details td:first-child {
    border-left: 1px solid #dee2e6;
    border-right: 0px solid #dee2e6;
}
::v-deep .b-table-has-details td:nth-child(2) {
    border-left: 0px solid #dee2e6;
    border-right: 0px solid #dee2e6;
}
/* constructing the hierarchy lines in the plussign column */
::v-deep .plusminussigntd {
    padding: 0;
    position: relative;
}
/* hiding the overflowing line behind transparent part of +/- icon */
::v-deep .plusminusbutton::before {
    content: "";
    background: white;
    z-index: -1;
    height: 37%;
    left: 12px;
    position: absolute;
    top: 32%;
    width: 10px;
}
::v-deep .b-table-has-details td:first-child::after {
    content: "";
    background: #a9b1b8;
    height: 42%;
    left: 16px;
    position: absolute;
    top: 60%;
    width: 0.5px;
    z-index: 1;
}
::v-deep .detail-row-table .plusminussigntd::before {
    content: "";
    background: #a9b1b8;
    width: 50%;
    left: 16px;
    position: absolute;
    top: 50%;
    height: 0.5px;
}
::v-deep .detail-row-table .plusminussigntd:first-child::after {
    content: "";
    background: #a9b1b8;
    height: 100%;
    left: 16px;
    position: absolute;
    top: -50%;
    width: 0.5px;
}
</style>
